<template>
  <div>
    <feather-icon icon="UserIcon" class="mr-2" svgClasses="w-5 h-5" />
    <span class="leading-none font-medium">Hero Image</span>

    <vs-input 
      class="w-full mt-4" 
      label="Hero Text" 
      :danger="validation.hero_text?true:false"
      :danger-text="validation.hero_text?validation.hero_text[0]:''"
      :value="form.hero_text"
      @input="updateForm({key: 'hero_text', value: $event})" />

    <vs-input 
      class="w-full mt-4" 
      label="Alt Text" 
      :danger="validation && validation.hero_image && validation.hero_image.alt?true:false"
      :danger-text="validation && validation.hero_image && validation.hero_image.alt?validation.hero_image.alt[0]:''"
      :value="form.hero_image.alt"
      @input="updateImageForm({field: 'hero_image', key: 'alt', value: $event})" />

    <file-pond
        name="test"
        class="w-full mt-4"
        ref="pond"
        label-idle="Drop Image here..."
        :allow-multiple="false"
        accepted-file-types="image/jpeg, image/png"
        instant-upload="false"
        :files="form.hero_image.img_url != '' ? form.hero_image.img_url : form.image"
        v-bind:required="!isEdit"
        @addfile="handleFileAdd"/>
        <span class="text-danger text-sm" v-show="validation && validation.hero_image && validation.hero_image.file?true:false">
        {{ validation && validation.hero_image && validation.hero_image.file?validation.hero_image.file[0]:''}}
        </span>

  </div>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'
// Import FilePond
import vueFilePond from 'vue-filepond';

// Import plugins
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm.js';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm.js';

// Import styles
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
const FilePond = vueFilePond( FilePondPluginFileValidateType, FilePondPluginImagePreview );

export default {
  props: {
    isEdit: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    FilePond
  },
  computed: {
    ...mapGetters({
        form: 'seo_page/getFormObj',
        images: 'banner/getImages',
        validation: 'validation/getValidationErrors',
    })
  },
  methods: {
    ...mapActions('seo_page', [
      'updateImageForm',
      'updateForm'
    ]),
    handleFileAdd(image_name, files) {
      this.updateImageForm({ field: 'hero_image', key: 'file', value: files.file})
    },
  }
}
</script>


<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
</style>
