<template>
  <div>
  <div>
    <div class="vx-row">
      <div class="vx-col w-full">
        <feather-icon icon="UserIcon" class="mr-2" svgClasses="w-5 h-5" />
        <span class="leading-none font-medium">Seo Page Details</span>
      </div>
      <div class="vx-col w-full md:w-3/3">
        <label class=" vs-input--label">Seopageable Type</label>
          <v-select
            :danger="validation.seopageable_type?true:false"
            :value="dropdown.seopageable_type"
            @input="updateForm({key: 'seopageable_type', value: $event})"
            :options="seoPageAbleTypes" />
            <span class="text-danger text-sm"
            v-show="validation.seopageable_type?true:false">
          {{ validation.seopageable_type?validation.seopageable_type[0]:''}}
        </span>
      </div>
      <div class="vx-col w-full md:w-3/3">
        <label class=" vs-input--label">Regions</label>
          <v-select
            :danger="validation.seopageable_id?true:false"
            :value="dropdown.seopageable_id"
            @input="updateForm({key: 'seopageable_id', value: $event})"
            :options="seopageable_type_dropdown" />
            <span class="text-danger text-sm"
            v-show="validation.seopageable_id?true:false">
          {{ validation.seopageable_id?validation.seopageable_id[0]:''}}
        </span>
      </div>
      <div class="vx-col w-full">
        <vs-input 
          class="w-full mt-4" 
          label="Title" 
          :danger="validation.title?true:false"
          :danger-text="validation.title?validation.title[0]:''"
          :value="form.title"
          @input="updateForm({key: 'title', value: $event})" />
      </div>
      <div class="vx-col w-full md:w-3/3">
        <vs-textarea
        class="w-full mt-4"
        label="Description"
        :value="form.description"
        @input="updateForm({ key: 'description', value: $event })"
      />
    </div>

    <div class="vx-col w-full">
        <vs-input 
          class="w-full mt-4" 
          label="Title Footer" 
          :danger="validation.title_footer?true:false"
          :danger-text="validation.title_footer?validation.title_footer[0]:''"
          :value="form.title_footer"
          @input="updateForm({key: 'title_footer', value: $event})" />
      </div>
      <div class="vx-col w-full md:w-3/3">
        <vs-textarea
        class="w-full mt-4"
        label="Description Footer"
        :value="form.description_footer"
        @input="updateForm({ key: 'description_footer', value: $event })"
      />
    </div>
      <div class="vx-col w-full md:w-3/3">
        <vs-input 
          class="w-full mt-4" 
          label="Details Duration" 
          :danger="validation.details_duration?true:false"
          :danger-text="validation.details_duration?validation.details_duration[0]:''"
          :value="form.details_duration"
          @input="updateForm({key: 'details_duration', value: $event})" />
      </div>
      <div class="vx-col w-full md:w-3/3">
        <vs-input 
          class="w-full mt-4" 
          label="Details Cost" 
          :danger="validation.details_cost?true:false"
          :danger-text="validation.details_cost?validation.details_cost[0]:''"
          :value="form.details_cost"
          @input="updateForm({key: 'details_cost', value: $event})" />
      </div>

      <div class="vx-col w-full md:w-3/3">
        <vs-input 
          class="w-full mt-4" 
          label="Details Fav Time" 
          :danger="validation.details_fav_time?true:false"
          :danger-text="validation.details_fav_time?validation.details_fav_time[0]:''"
          :value="form.details_fav_time"
          @input="updateForm({key: 'details_fav_time', value: $event})" />
      </div>

      <div class="vx-col w-full md:w-3/3">
        <vs-input 
          class="w-full mt-4" 
          label="Details Permit" 
          :danger="validation.details_permit?true:false"
          :danger-text="validation.details_permit?validation.details_permit[0]:''"
          :value="form.details_permit"
          @input="updateForm({key: 'details_permit', value: $event})" />
      </div>

      <div class="vx-col w-full md:w-3/3">
        <vs-input 
          class="w-full mt-4" 
          label="Details Transport" 
          :danger="validation.details_transport?true:false"
          :danger-text="validation.details_transport?validation.details_transport[0]:''"
          :value="form.details_transport"
          @input="updateForm({key: 'details_transport', value: $event})" />
      </div>
  </div>
  </div>
  </div>
</template>

<script>

import vSelect,{vOption} from 'vue-select'
import { mapGetters,mapActions } from 'vuex'
import { SEO_PAGEABLE_TYPE } from '../../constant';

export default {  
  data() {
    return {
      seoPageAbleTypes: SEO_PAGEABLE_TYPE
    }
  },
  components: {
    vSelect,
    vOption
  },

  computed: {
    ...mapGetters({
        form: 'seo_page/getFormObj',
        dropdown: 'seo_page/getDropdownObj',
        seopageable_type_dropdown: 'seo_page/getSeopageableTypeDropdown',

        validation: 'validation/getValidationErrors',
    })
  },
  methods: {
    ...mapActions({
      updateForm: 'seo_page/updateForm',
    })
  }
}
</script>
