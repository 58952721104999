<template>
  <div>
  <div>
    <div class="vx-row">
      <div class="vx-col w-full">
        <feather-icon icon="UserIcon" class="mr-2" svgClasses="w-5 h-5" />
        <span class="leading-none font-medium">Itinerary Details</span>
      </div>
      <div class="vx-col w-full" v-for="(itinerary, index) in form.itinerary" :key="index">
        <div class="vx-col w-full">
          <vs-input 
            class="w-full mt-4" 
            label="Title" 
            :value="itinerary.title"
            @input="updateItineraryForm({key: 'title', index, value: $event})" />
        </div>
        <div class="vx-col w-full md:w-3/3">
          <vs-textarea
            class="w-full mt-4"
            label="Description"
            :value="itinerary.description"
            @input="updateItineraryForm({ key: 'description', index, value: $event })"
          />
        </div>
      </div>

      <div class="vx-col flex flex-wrap items-center justify-end">
        <vs-button @click="addMoreItinerary" class="ml-auto"> + Add More</vs-button>
      </div>
  </div>
  </div>
  </div>
</template>

<script>

import vSelect,{vOption} from 'vue-select'
import { mapGetters,mapActions } from 'vuex'

export default {  
  components: {
    vSelect,
    vOption
  },

  computed: {
    ...mapGetters({
        form: 'seo_page/getFormObj',
    })
  },
  methods: {
    ...mapActions({
      updateItineraryForm: 'seo_page/updateItineraryForm',
      addMoreItinerary: 'seo_page/addMoreItinerary',
    })
  }
}
</script>
