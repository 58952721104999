<template>
  <div>
    <feather-icon icon="UserIcon" class="mr-2" svgClasses="w-5 h-5" />
    <span class="leading-none font-medium">Hotel Images</span>
    <vs-button icon="save" type="border" size="small" @click="cloneGallery" class="plus-icons" color="primary"> Add More Image</vs-button>
    <div v-for="(image, index) in form.gallery" :key="index" class="images-comp">
      <file-pond
        name="test"
        ref="pond"
        label-idle="Drop image here..."
        :allow-multiple="false"
        instantUpload="false"  
        allowImagePreview="true"
        allowFileMetadata="true"
        :fileMetadataObject="{index: index}"
        allowImageSizeMetadata="true"
        accepted-file-types="image/jpeg, image/png"
        :allowImageTransform="false"
        :files="image.img_url != '' ? image.img_url : form.image"
        v-bind:required="!isEdit"       
        @addfile="handleFileChange" />
        <span class="text-danger text-sm" v-show="validation && validation.gallery && validation.gallery[index].file?true:false">
        {{ validation && validation.gallery && validation.gallery.file?validation.banner_image.file[0]:''}}
        </span>

        <vs-input 
          class="w-full mt-4" 
          label="Alt Text" 
          :danger="validation && validation.gallery && validation.gallery[index].alt?true:false"
          :danger-text="validation && validation.gallery && validation.gallery[index].alt?validation.gallery[index].alt[0]:''"
          :value="image.alt"
          @input="updateAltForm({key: 'alt', value: $event, index: index})" /> 
          <div class="demo-alignment">
          <vs-button icon="delete" type="border" size="small" @click="confirmRemoveClonedHotelImage(index)" class="cross-icons" v-if="showRemoveBtn && index != 0" color="danger">Remove</vs-button> 
      </div>    
    </div>
  </div>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'

import vueFilePond from 'vue-filepond';
// Import plugins
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm.js';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm.js';
import FilePondPluginFileMetadata from 'filepond-plugin-file-metadata';
// Import styles
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
const FilePond = vueFilePond( FilePondPluginFileValidateType, FilePondPluginImagePreview, FilePondPluginFileMetadata );

export default {
  props: {
    isEdit: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    FilePond
  },
  data: () => {
    return{
      imageId: '',
      showRemoveBtn: false,
      imageIndex: '',
    }
  },
  computed: {
    ...mapGetters({
        form: 'seo_page/getFormObj',
        hotelImages: 'hotel/getHotelImages',
        validation: 'validation/getValidationErrors'
    })
  },
  mounted() {
    console.log(this.form.gallery);
  },
  methods: {
    ...mapActions({
      updateGalleryForm: 'seo_page/updateGalleryForm',
      addMoreGallery: 'seo_page/addMoreGallery'
    }),
    handleFileChange(file_name, files) {
      var index = files.getMetadata('index');
      this.updateGalleryForm({ key: 'file', index, value: files.file })
    },

    updateAltForm({ index, value }) {
      this.updateGalleryForm({ key: 'alt',index: index, value: value })

     // this.updateForm({key: 'alt', value:{index: alt.index, value: alt.value}});
    },

    removeImage(){
      this.$vs.notify({ 
            text: 'Hotel Images Removed successfully.',
            title: 'Hotel Images Removed',
            color: 'success',
            position: 'top-right'
        });
    },

    cloneGallery(){
        this.addMoreGallery();
      //  this.showRemoveBtn = this.form.gallery.length > 1 ? true : false;
    },

    removeHotelImage(){
      this.updateForm({key:{key: "RemoveImage", index: this.imageIndex}, value: {image: "", alt: "", source:[{source: null, options: {type: '', file:''}
            }]}});
      this.showRemoveBtn = this.hotelImages.length > 1 ? true : false;
    },

    confirmRemoveClonedHotelImage(index){
      this.imageIndex = index;
        this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: `Confirm Remove`,
        text: `You are about to remove image`,
        accept: this.removeHotelImage,
        acceptText: "Remove"
      })
    },
  }
}
</script>


<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
  
  .plus-icons{
    float: right;
    cursor: pointer;
    color: #0c78e7;
  }
  .images-comp{
    margin-top: 35px;
  }
}
</style>
